import React from "react"
import Layout from 'components/Layout'
import SEO from "components/seo"
import {Link} from 'gatsby'

const NotFoundPage = () => (
  <Layout path="/404/" uk>
    <SEO title="404: Not found" />
    <div className="text-center bg--black py-5">
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Link to="/">Back to homepage</Link>
    </div>
  </Layout>
)

export default NotFoundPage
